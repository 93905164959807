<template>
    <div>
        <b-container fluid class="landing_container_main">
            <div class="landing_bg_filter"></div>
            <b-container class="p-0">
            <b-row no-gutters >
                <b-col lg="8">
                    <b-col md="12">
                        <div class="landing_title">Мобильное приложение<br>"Окей Город"</div>
                    </b-col>
                    <b-col md="12" lg="6">
                        <a class="landing_btn" href="https://play.google.com/store/apps/details?id=com.goroda.cities" target="_blank" rel="nofollow" @click="reachYA('download_googlePlay')">
                            <div class="landing_icons android"></div>
                            <div class="landing_text">
                                <h6>Скачать на</h6>
                                <span>Google Play</span>
                            </div>
                        </a>
                    </b-col>
                    <b-col md="12" lg="6">
                        <a class="landing_btn" href="https://apps.apple.com/ru/app/id1361334339" target="_blank" rel="nofollow" @click="reachYA('download_ios')">
                            <div class="landing_icons ios"></div>
                            <div class="landing_text">
                                <h6>Скачать в</h6>
                                <span>App Store</span>
                            </div>
                        </a>
                    </b-col>
                    <b-col md="12" lg="6">
                        <a class="landing_btn" href="https://appgallery.huawei.com/#/app/C103438881" target="_blank" rel="nofollow" @click="reachYA('download_appgallery')">
                            <div class="landing_icons appgallery"></div>
                            <div class="landing_text">
                                <h6>Скачать в</h6>
                                <span>App Gallery</span>
                            </div>
                        </a>
                    </b-col>
                </b-col>
                <b-col lg="4">
                    <div class="phone_slider_box">
                        <slick id="slick" ref="slick" :options="slickMain" class="landing_slick">
                            <img alt="image" src="/images/landing/1.jpg" class="open-tinybox slick_image_main">
                            <img alt="image" src="/images/landing/2.jpg" class="open-tinybox slick_image_main">
                            <img alt="image" src="/images/landing/3.jpg" class="open-tinybox slick_image_main">
                            <img alt="image" src="/images/landing/4.jpg" class="open-tinybox slick_image_main">
                            <img alt="image" src="/images/landing/5.jpg" class="open-tinybox slick_image_main">
                            <img alt="image" src="/images/landing/6.jpg" class="open-tinybox slick_image_main">
                        </slick> 
                    </div>
                       
                    
                        
                        
                    
                </b-col>
            </b-row>
            </b-container>
        </b-container>
    </div>
</template>
<script>
import 'slick-carousel/slick/slick.css';
import Slick from 'vue-slick';
export default {
    name: "Landing",
    components: {
        Slick
    },
    data() {
        return {
            slickMain: {
                slidesToShow: 1,
                arrows: false,
                speed: 500,
                slidesToScroll: 1,
                autoplay: true,
                infinite: true,
                swipe: true,
                centerMode: true,


            },
        }
    },
    methods: {
        reachYA(name) {
            this.$metrika.reachGoal(/*{ target: name }*/name);
        }
    },

}
</script>
<style scoped>
@media only screen and (max-width: 991px) {
    .landing_title {
        text-align: center;
        font-size: 1.3rem;
    }
    
    .landing_container_main {
        padding-top: 40px;
    }

    .col-md-12 {
        padding: 0 !important;
    }

    .landing_btn {
        justify-content: center;
    }
}
@media only screen and (min-width: 992px) {
    .landing_title {
        text-align: left;
        font-size: 1.5rem;
    }

    .landing_container_main {
        display: flex;
        align-items: center;
    }

    .landing_btn {
        justify-content: center;
    }

}

a:hover {
    text-decoration: none;

}

h6 {
    margin: 0;

}

.landing_title {
    color: #fff;    
    margin-bottom: 40px;
    
}

.landing_container_main {
    
    background-image: url("/images/back_landing.jpg");

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 100vh;
    height: 100%;

    transition: all .2s ease-in-out;
}

.landing_bg_filter {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
}

.landing_container {
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #968ff4 45%, #6ab6e5 70%);
    transition: all .2s ease-in-out;
}

.landing_btn {
    height: 77px;
    border: 1px solid rgba(255, 255, 255, .4);
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    transition: all .2s ease-in-out;
    min-width: 250px;
    margin-bottom: 20px;
}

.landing_btn:hover {
    border: 1px solid transparent;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .1);
    background-color: #fff;
}

.landing_btn:hover .landing_icons {
    background-color: #889cef;
}

.landing_btn:hover .landing_text {
    color: #889cef;
}

.landing_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 30px;
    padding-right: 20px;
    color: #fff;
    font-family: poppins, sans-serif;
    transition: all .2s ease-in-out;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 400;
}

.landing_text span {}

.landing_box {
    min-height: 100vh;
}
.landing_icons {
    width: 31px;
    height: 38px;
    background-color: #fff;
    display: block;
    transition: all .2s ease-in-out;

}

.android {
    mask-image: url('/images/icon/android.svg');
    mask-repeat: no-repeat;
    mask-position: center;
}

.ios {
    mask-image: url('/images/icon/ios.svg');
    mask-repeat: no-repeat;
    mask-position: center;
}

.appgallery {
    mask-image: url('/images/icon/Huawei_AppGallery.svg');
    mask-repeat: no-repeat;
    mask-position: center;
}

.phone_slider_box {
    position: relative;
    display: block;
}

.landing_slick {

}

.landing_slick * {
    padding: 10px;


}

.landing_slick img {
    border-radius: 24px;
    max-height: 500px;
    height: auto;
    width: auto !important;
    margin: auto;
    max-width: 100%;
}


</style>